import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environment';
import { Result } from '@shared/models/result.model';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  private url = `${environment.apis.linkroom}/assets`;
  constructor(private http: HttpClient) {}

  deleteMany(data: any[]) {
    return this.http.put<Result>(`${this.url}/delete-many/from-xls`, data);
  }
}
